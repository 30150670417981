<template>
  <div style="position:relative">
    <div class="panel demoWrapper">
      <div class="basic-info">
        <div class="basic-info-top">Invoice info</div>
        <div class="basic-info-con">
          <div v-if="!ifTH">
            <el-form
              :label-position="labelPosition"
              label-width="260px"
              :model="formLabelAlign"
            >
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="Enterprise ID:">
                    <font v-text="formLabelAlign.id" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Invoice Bank Account:">
                    <font v-text="formLabelAlign.invoiceBankNumber" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Invoice Register Address:">
                    <Tooltip
                      :content="formLabelAlign.invoiceRegisterAddress"
                      class="wid190"
                      ref-name="tooltipOver2"
                    />
                    <!-- <font v-text="formLabelAlign.invoiceRegisterAddress" /> -->
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Invoice Register Enterprise ID:">
                    <Tooltip
                      :content="formLabelAlign.invoiceCompanyName"
                      class="wid190"
                      ref-name="tooltipOver1"
                    />
                    <!-- <font v-text="formLabelAlign.invoiceCompanyName" /> -->
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Invoice Register Phone No:">
                    <font v-text="formLabelAlign.invoiceRegisterPhone" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Invoice Register Zip:">
                    <font v-text="formLabelAlign.invoiceRegisterZipcode" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Name of Deposit Bank:">
                    <Tooltip
                      :content="formLabelAlign.invoiceBankDeposit"
                      class="wid190"
                      ref-name="tooltipOver3"
                    />
                    <!-- <font v-text="formLabelAlign.invoiceBankDeposit" /> -->
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Taxpayer Identification Number:">
                    <font v-text="formLabelAlign.invoiceTaxpayerNumber" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-else>
            <el-form
              :label-position="labelPosition"
              label-width="130px"
              :model="formLabelAlign"
            >
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="Name:">
                    <font v-text="formLabelAlign.invoiceCompanyName" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Branch Name:">
                    <font
                      v-text="
                        formLabelAlign.invoiceBranchName == '1'
                          ? 'Head Office'
                          : formLabelAlign.invoiceBranchName == '2'
                            ? 'Branch'
                            : formLabelAlign.invoiceBranchName == '3'
                              ? 'Individual'
                              : ''
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Branch ID">
                    <font v-text="formLabelAlign.invoiceBranchId" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Tax ID:">
                    <font v-text="formLabelAlign.invoiceTaxpayerNumber" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Address:">
                    <font v-text="formLabelAlign.invoiceRegisterAddress" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Postal Code:">
                    <font v-text="formLabelAlign.invoiceRegisterZipcode" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Email:">
                    <font v-text="formLabelAlign.invoiceRegisterEmail" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="panel demoWrapper button-add">
      <el-form
        :model="formLabelAlign1"
        :label-position="labelPosition1"
        class="button-add-l"
      >
        <el-form-item label="Audit Result" prop="radio">
          <el-radio-group v-model.trim="formLabelAlign1.radio">
            <el-radio :label="1">Passed</el-radio>
            <el-radio :label="2">Rejected</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Audit Result" prop="remark">
          <textarea
            v-model.trim="remark"
            class="inputTextarea"
            placeholder="please enter "
          />
        </el-form-item>
      </el-form>
      <div class="button-add-r">
        <el-button
          type="primary"
          class="submitted"
          style="padding: 0 20px;"
          @click="Submit"
        >
          Submit
        </el-button>
        <el-button type="primary" style="padding: 0 20px;" @click="back">
          Back
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css';
import { examineBusiness ,getEnterpriseInfo} from '@/api/enterprise/enterprise';
import Tooltip from '../../components/tooltipOver'

export default {
  components:{
    Tooltip
  },
  props: {
    obj: Object
  },
  data() {
    return {
      // 添加企业信息数据
      radio: '',
      input123: '',
      value: '',
      labelPosition: 'left',
      // 基本信息数据
      formLabelAlign: {},
      formLabelAlign1: { radio: 0 },
      labelPosition1: 'left',
      remark: '',
      ifTH: false,
      id:this.$route.query.id
    };
  },
  created() {
    this.info();
    if (process.env.VUE_APP_BASE_API == '/apiid') {
      this.ifTH = false;
    } else if (process.env.VUE_APP_BASE_API == '/apith') {
      this.ifTH = true;
    }
  },
  methods: {
    //  返回上一级
    back() {
      this.$router.go(-1);
    },
    info() {
      getEnterpriseInfo(this.id).then(res=>{
        if(res.code==200){
          this.formLabelAlign=res.data
          let nameState = 0;
          if (res.data.baseInfoAuditState == 1) {  // 回显通过
            nameState = 1;
          } else if (res.data.baseInfoAuditState == 2) {  // 回显拒绝
            nameState = 2;
          } else { // 待审核
            nameState = 0;
          }
          this.formLabelAlign1.radio = nameState;
          this.remark = res.data.remark || '';
        }
      })
    },
    Submit() {
      if(this.formLabelAlign1.radio<1 ){
        this.$message(
          {
            message: 'Please select audit result',
            type: 'error'
          });
        return
      }
      if(this.formLabelAlign1.radio==2 && !this.remark){
        return this.$message({message:'Please fill in the reason for rejection'})
      }
      let arr = {
        id: this.formLabelAlign.id,
        remark: this.remark,
        modifyEnterprise: 0,
        modifyInvoice: 1,
        modifyBank: 0,
        reviewStatus: this.formLabelAlign1.radio, //审核通过
        // creator: 11111
      };
      let obj = JSON.stringify(arr);
      examineBusiness(obj).then(res => {
        if (res.code == 200) {
          this.$message({ message: 'Operation succeeded', type: 'success' });
          this.$router.push('invoice');
        }else{
          this.$message({type:'error',message:res.msg});
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.demoWrapper {
  /deep/.el-input {
    // width: 200px;
    margin-right: 30px;
  }
  /deep/.el-input__inner {
    width: 220px;
    height: 32px;
    line-height: 32px;
  }
  .basic-info {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 11px;
    // height: 513px;
    height: 560px;
    border-radius: 8px;

    .basic-info-top {
      height: 67px;
      line-height: 67px;
      font-size: 20px;
      font-family: Arial;
      font-weight: normal;
      color: rgba(36, 37, 38, 1);
    }
    .basic-info-con {
      overflow: hidden;
      overflow-x: visible;
      padding-left: 27px;
      padding-right: 27px;
      .el-row {
        width: 1400px;
      }
    }
    .basic-foot {
      height: 80px;
      // display: flex;
      padding-top: 39px;
    }
  }
  .invoice-info {
    margin-top: 20px;
  }
}
.button-add {
  position: fixed;
  height: 250px;
  width: 83.5%;
  bottom: 4%;
  padding-top: 20px;
  box-shadow: 3px 5px 9px #000;
  clear: both;
  .button-add-l {
    padding-left: 20px;
  }
  .button-add-r {
    width: 300px;
    position: relative;
    bottom: 200px;
    left: 80%;
  }
  .inputTextarea {
    outline: none;
    width: 500px;
    height: 100px;
    margin-bottom: 20px;
  }
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-link--primary {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
</style>
